import React from "react";
import { graphql } from "gatsby";

import Layout from "../../../../shared/src/components/layout";
import Head from "../../../../shared/src/components/head";

import * as styles from "./index.module.css";

const Consulting = ({ data, location }) => {
  const { menu } = data.site.siteMetadata;

  return (
    <Layout location={location} menu={menu} title="MN">
      <Head title="IT Consulting" keywords={[`blog`, `consulting`, `it`]} />
      <div className={styles.hero}>
        <h1> IT Consulting </h1>
        <h2>
          Quality,
          <br />
          Integrity,
          <br />
          Humor.
        </h2>
        <h4>
          I practice process driven IT consulting for organizations. Experience
          and quality begets efficiency and I deliver all three to my clients. I
          build elegant, robust, and rigorously tested IT solutions using a
          process that has been forged and honed over 20 years.
        </h4>
      </div>
      <main>
        <div className={styles.section}>
          <div>
            <h2>Phased Project Approach</h2>
          </div>
          <div>
            <p>
              Each project is carried out in distinct, linear phases. These
              phases call out specific milestones that are marked by
              deliverables. Deliverables can take the form of documents,
              functional systems, or accomplishments. At the conclusion of each
              project phase, success is measured and evaluated by each party.
              Sometimes, the conclusion of a phase is the appropriate time to
              adjust the required project deliverables, or even its goals and
              scope.
            </p>
          </div>
        </div>

        <div className={styles.section}>
          <div className={styles.title}>
            <h3>Phase 1: Discover</h3>
          </div>
          <div className={styles.description}>
            <p>
              During first phase of solution delivery, baseline expectations;
              environmental details; operational challenges; and project success
              criteria are established. Network and environment discovery is
              performed and assumptions made during the proposal process are
              validated. This process also presents an opportunity to mitigate
              concerns early on in the project.
            </p>
          </div>
        </div>

        <div className={styles.section}>
          <div className={styles.title}>
            <h3>Phase 2: Design & Plan</h3>
          </div>
          <div className={styles.description}>
            <p>
              The remainder of the project is mapped and the high-level design
              that was presented during the proposal process is expanded. The
              core project documentation is drafted, typically including Project
              Plan, Design, Implementation Plan, and Test Plan. Collaborative
              design sessions allow our team to work closely with your IT
              operations and management staff, facilitating a reciprocal
              exchange as all design aspects are thoroughly discussed,
              determined, and iterated.
            </p>
          </div>
        </div>

        <div className={styles.section}>
          <div className={styles.title}>
            <h3>Phase 3: Test</h3>
          </div>
          <div className={styles.description}>
            <p>
              Build the lab environment and test it against Test Plan and
              Implementation Plan. Build the pilot environment and test it
              against Implementation Plan, User Experience, and Success
              Criteria. Revise Project Plan and Documentation, informed by the
              lessons learned.
            </p>
          </div>
        </div>

        <div className={styles.section}>
          <div className={styles.title}>
            <h3>Phase 4: Implement</h3>
          </div>
          <div className={styles.description}>
            <p>
              Measure twice, cut once. Most of the work is already complete
              before beginning the Implementation Phase. A project and
              subsequent operational environment will be more efficient in the
              long run by being deliberate and careful in planning and testing
              before action is taken. Further, the resulting deliverables will
              last longer, providing better value.
            </p>
          </div>
        </div>

        <div className={styles.section}>
          <div className={styles.title}>
            <h3>Phase 5: Wrap Up</h3>
          </div>
          <div className={styles.description}>
            <p>
              Support and training are provided. Documentation is finalized.
              Although Knowledge Transfer happens throughout the project, a
              special emphasis is placed on it during this phase to make sure
              everyone concludes the project with mutual understanding. Project
              debrief is held with lessons learned and outstanding issues
              recorded.
            </p>
          </div>
        </div>
      </main>
    </Layout>
  );
};

export default Consulting;

export const query = graphql`
  query {
    site {
      siteMetadata {
        menu
      }
    }
  }
`;
